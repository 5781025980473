<template>
  <div>
    <ax-table
      ref="roleTable"
      :show-search="true"
      :searchForm="searchForm"
      :searchActions='searchActions'
      :columns="columns"
      :dataSourceApi="api.list"
      :dataSourceParams="dataSourceParams"
      :sqlParams="sqlParams"
      :clickable="false"
      :scroll="{ y: '55vh', x: '80vw' }"
      @change="changeValue"
      @export="expor"
      @on-search-click="onSearchClick"
      @action-column-click="actionColumnClick"
    >

    </ax-table>
  </div>
</template>

<script>

import { initGridFormData } from '@/common/tools'
import api from './api'

const searchForm = [
  { label: '操作人', type: '', model: 'userName', options: {}, formItem: {}, col: { span: 6 } },
  { label: '访问主机', type: '', model: 'ipaddr', options: {}, formItem: {}, col: { span: 6 } },
  { label: '操作类型', type: 'select', model: 'operationType', options: {}, formItem: {}, col: { span: 6 } }
]
export default {
  components: {},
  data () {
    return {
      api,
      startTime: '',
      endTime: '',
      uoloadResult: false,
      searchForm: initGridFormData(searchForm, { layout: 'horizontal', labelWidth: 90 }),
      searchActions: [
        { name: 'search', text: '查询' },
        { name: 'reset', text: '重置' },
        { name: 'export', text: '导出Excel' }
      ],
      sqlParams: { like: ['userName', 'ipaddr'] },
      columns: this.$ax.tools.initColumn([
        {
          title: '操作类型',
          dataIndex: 'operationType_dictText',
          width: '15%'
        },
        {
          title: '操作时间',
          dataIndex: 'createTime',
          width: '30%'
        },
        {
          title: '操作人',
          dataIndex: 'userName',
          width: '20%'
        },
        {
          title: '当前版本',
          dataIndex: 'version',
          width: '10%'
        },
        {
          title: '访问主机',
          dataIndex: 'ipaddr',
          width: '25%'
        }
      ]
      ),
      dataSourceParams: {
      }
    }
  },
  async created () {
    await this.equipmentTypeDic()
  },
  methods: {
    getList () {
      this.$refs.roleTable.getDataSource()
    },
    async equipmentTypeDic () {
      await api.dictData({ dicKind: 'app_operation_type' }).then(res => {
        const options = res.data.map(res => { return { label: res.dicDesc, value: res.dicCode } })
        this.equipmentTypeList = options
        this.$refs.roleTable.$children[0].$refs.searchForm.setFormItemProp('operationType', { options: { options, allowClear: true } })
      })
    },
    changeValue (value, key) {

    },
    changeValueForm (value, key) {
    },
    actionColumnClick (args) {

    },

    expor () {
      const values = JSON.parse(JSON.stringify(this.searchForm.formValues))
      if (values.userName !== undefined && values.userName !== '') {
        values.userName = '*' + values.userName + '*'
      }

      if (values.ipaddr !== undefined && values.ipaddr !== '') {
        values.ipaddr = '*' + values.ipaddr + '*'
      }

      api.exportXls({
        fileName: 'APP版本管理日志列表.xls',
        params: values
      })
    },
    onSearchClick ({ btn, formValues }) {
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-table-thead {
  background: rgba(100, 191, 160, 0.5);
}
</style>
